import React from 'react';
import {
  useQuery,
  gql,
  ApolloError,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';
import { Connection, IdentityPolicyResource } from '@quality24/typings';
import {
  mergePolicy,
  hasPermission as hasIdentityPermission,
  getResourceAccess as getIdentityResourceAccess,
  GetResourceAccessResult,
} from '@quality24/identity-utils';

/**
 * GraphQL set new password mutation
 */
export const FETCH_IDENTITY_POLICIES = gql`
  query FetchAccountIdentityPolicies($userId: UUID!) {
    policies: identityPolicies(
      orderBy: CREATED_AT_ASC
      filter: {
        identityPolicyConnections: { some: { userId: { equalTo: $userId } } }
      }
    ) {
      nodes {
        id
        value
      }
    }
  }
`;

export interface QueryResult {
  policies: Connection<Pick<IdentityPolicyResource, 'id' | 'value'>>;
}

export interface UseIdentityPayload<
  TAction extends string = string,
  TResource extends string = string,
> {
  loading: boolean;
  error?: ApolloError;
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<QueryResult>>;
  hasPermission: (permission: TAction, resources?: TResource[]) => boolean;
  getResourceAccess: (
    resourcePrefix: string,
    permission: TAction,
  ) => GetResourceAccessResult;
}

export const useAccountIdentity = <
  TAction extends string = string,
  TResource extends string = string,
>(
  userId: string,
): UseIdentityPayload<TAction, TResource> => {
  // Perform the query or fetch from cache
  const { data, loading, error, refetch } = useQuery<QueryResult>(
    FETCH_IDENTITY_POLICIES,
    {
      variables: { userId },
      skip: !userId,
      fetchPolicy: 'cache-first',
      // @note cache-only seems to be broken in current apollo version
      // nextFetchPolicy: 'cache-only',
    },
  );

  // Merge the permissions
  const identity = React.useMemo(
    () => (data ? mergePolicy(data.policies.nodes.map((p) => p.value)) : null),
    [data],
  );

  /**
   * Checks if user has the given permission. If `resources` is defined, will try to match only
   * permissions defined in the required resources (otherwise will try to match the whole policy).
   */
  const hasPermission = React.useCallback(
    (permission: TAction, resources?: TResource[]) => {
      // By default, all permissions are restricted
      if (!identity) return false;
      return hasIdentityPermission(identity, permission, resources);
    },
    [identity],
  );

  /**
   * Retrieves a list of resource Ids that this policy has access to or true if a '*' is found.
   * Could be seen as the question: "Are there any resources that the user have the access permission X?"
   */
  const getResourceAccess = React.useCallback(
    (resourcePrefix: string, permission: TAction) => {
      if (!identity) return { type: 'none' } as GetResourceAccessResult;
      return getIdentityResourceAccess(identity, resourcePrefix, permission);
    },
    [identity],
  );

  return React.useMemo(
    () => ({
      loading,
      error,
      refetch,
      hasPermission,
      getResourceAccess,
    }),
    [error, getResourceAccess, hasPermission, loading, refetch],
  );
};

export default useAccountIdentity;
