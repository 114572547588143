import { gql, useQuery } from '@apollo/client';
import { Connection } from '@quality24/typings';
import React from 'react';
import { useAccountIdentity } from './useAccountIdentity';
import { AllowedResource, UseAllowedResourcePayload } from './types';

export const GET_ALLOWED_UNITS = gql`
  query GetAllowedUnits($includedUnitIds: [UUID!], $excludedUnitIds: [UUID!]) {
    units: hospitalUnits(
      orderBy: ORDER_ASC
      filter: {
        or: [{ id: { in: $includedUnitIds, notIn: $excludedUnitIds } }]
      }
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

export interface AllowedUnitsQueryResult {
  units: Connection<UseAllowedResourcePayload>;
}

/**
 * Hook Allowed Units
 * get list of items and ids released by `permission`
 *
 * @deprecated Use `useAllowedResources` instead
 *
 * @param userId
 * @param permission
 * @returns
 */
const useAllowedUnits = (
  userId: string,
  permission: string,
): AllowedResource<UseAllowedResourcePayload> => {
  const { getResourceAccess, loading: accLoading } = useAccountIdentity(userId);

  const unitPermission = React.useMemo(
    () => getResourceAccess('loc:building', permission),
    [getResourceAccess, permission],
  );

  const { data, loading, error } = useQuery<AllowedUnitsQueryResult>(
    GET_ALLOWED_UNITS,
    {
      skip: accLoading || unitPermission.type === 'none',
      variables: {
        includedUnitIds:
          unitPermission.type === 'allowlist' ? unitPermission.list : null,
        excludedUnitIds:
          unitPermission.type === 'denylist' ? unitPermission.list : null,
      },
    },
  );

  const allowedUnits = React.useMemo(() => {
    if (data?.units && !loading && !error) {
      return {
        items: data.units.nodes.map((unit) => unit),
        ids: data.units.nodes.map((unit) => unit.id),
        isEvery: unitPermission.type === 'all',
      };
    }
    return { items: [], ids: [], isEvery: false };
  }, [data?.units, error, loading, unitPermission]);

  return React.useMemo(
    () => ({ ...allowedUnits, loading, error }),
    [allowedUnits, error, loading],
  );
};

export default useAllowedUnits;
