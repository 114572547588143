import * as React from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { ViewportList } from 'react-viewport-list';

import { SelectOption } from '../../../@types/shared';
import Spinner from '../../atoms/Spinner';
import FilterControl, { FilterControlProps } from '../../atoms/FilterControl';
import Overlay from '../../atoms/Overlay';
import Checkbox from '../../atoms/Checkbox';
import Radio from '../../atoms/Radio';
import DropdownItem from '../../atoms/DropdownItem';
import Surface from '../../atoms/Surface';
import SearchBar from '../../molecules/SearchBar';
import Text from '../../atoms/Text';

import styles from './MultipleSelectFilter.module.scss';

// Interfaces
// //////////////////////////

interface CheckboxProps {
  type: 'checkbox';
  value: Array<string>;
}

interface RadioSelectProps {
  type: 'radio' | 'select';
  value: string;
}

export type PropsCheckBox = Omit<
  FilterControlProps,
  | 'value'
  | 'type'
  | 'readOnly'
  | 'prepend'
  | 'onClick'
  | 'onChange'
  | 'controlId'
> & {
  name: string;
  size?: 'sm' | 'md';
  labelSize?: 'sm' | 'md';
  icon?: FontAwesomeIconProps['icon'];
  options: Array<SelectOption>;
  label?: string;
  helperText?: string;
  errorText?: string;
  loading?: boolean;
  search?: string;
  onOpen?: () => void;
  onChange?: (name: string, newValue: string | Array<string>) => void;
  onSearch?: (search: string) => void;
  disabled?: boolean;
  fullMode?: boolean;
  withSearch?: boolean;
} & CheckboxProps;

export type PropsSelect = Omit<
  FilterControlProps,
  | 'value'
  | 'type'
  | 'readOnly'
  | 'prepend'
  | 'onClick'
  | 'onChange'
  | 'controlId'
> & {
  name: string;
  variant?: 'default' | 'outline';
  size?: 'sm' | 'md';
  labelSize?: 'sm' | 'md';
  icon?: FontAwesomeIconProps['icon'];
  options: Array<SelectOption>;
  label?: string;
  helperText?: string;
  errorText?: string;
  loading?: boolean;
  search?: string;
  onOpen?: () => void;
  onChange?: (name: string, newValue: string | Array<string>) => void;
  onSearch?: (search: string) => void;
  disabled?: boolean;
  fullMode?: boolean;
  withSearch?: boolean;
} & RadioSelectProps;

export type Props = PropsCheckBox | PropsSelect;

const parseValue = (value: Props['value']) => {
  if (Array.isArray(value) && value.length === 0) {
    return [''];
  }
  return value;
};

/**
 * <MultipleSelectFilter> component
 */
export const MultipleSelectFilter: React.FunctionComponent<Props> = ({
  id,
  className,
  style,
  name,
  type,
  label,
  icon,
  loading,
  value,
  search: controlledSearch,
  variant = 'default',
  labelSize = 'sm',
  size = 'sm',
  options,
  helperText,
  errorText,
  onOpen,
  onChange,
  onSearch,
  disabled = false,
  fullMode = false,
  withSearch = false,
  ...inputProps
}) => {
  const [show, setShow] = React.useState(false);
  const [target, setTarget] = React.useState<HTMLDivElement | null>(null);
  const [selected, setSelected] = React.useState(parseValue(value));
  const viewportRef = React.useRef<HTMLDivElement | null>(null);

  const [search, setSearch] = React.useState('');
  const searchBarRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (searchBarRef.current && show) {
      searchBarRef.current.focus();
    }
  });

  const filteredOptions = React.useMemo(
    () =>
      options.filter(
        (option) =>
          option.label
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) || option.id === '',
      ),
    [options, search],
  );

  const inputId = id ?? uuid();

  // Get value to display
  const displayValue = React.useMemo(() => {
    const parsedValue = parseValue(value);
    if (Array.isArray(parsedValue)) {
      return options
        .filter((opt) => parsedValue.includes(opt.id))
        .map((opt) => opt.label)
        .join(', ');
    }
    return options.find((opt) => opt.id === parsedValue)?.label;
  }, [value, options]);

  /**
   * Handle change to selection when popover is open
   */
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.type === 'radio') {
        setSelected(event.target.value);
      } else if (event.target.type === 'checkbox') {
        const checkSelections = selected as string[];
        // Reset selection if:
        //  - default (key: '') is selected
        //  - no options is selected
        if (
          event.target.value === '' ||
          (!event.target.checked && checkSelections.length === 1)
        ) {
          setSelected(['']);
          return;
        }
        setSelected(
          event.target.checked
            ? [...checkSelections.filter((e) => e !== ''), event.target.value]
            : checkSelections.filter((e) => e !== event.target.value),
        );
      }
    },
    [selected],
  );

  /**
   * Handle change to selection when popover is open
   */
  const handleSelect = React.useCallback(
    (v: string) => {
      setSelected(v);
      setShow(false);
      if (onChange && type === 'select') {
        onChange(name, v);
      }
    },
    [onChange, name, type],
  );

  /**
   * Notify changes when overlay is closed
   */
  const handleOverlayHide = React.useCallback(() => {
    if (onChange && type === 'checkbox') {
      onChange(name, selected as string[]);
    } else if (onChange && type !== 'checkbox') {
      onChange(name, selected as string);
    }
  }, [name, type, selected, onChange]);

  return (
    <div
      className={classNames(styles.wrapper, className, {
        disabled,
        error: !!errorText,
      })}
      style={style}
    >
      {label && (
        <Text
          as="label"
          className="mb-2"
          size={labelSize === 'sm' ? 'sm2' : 'md'}
          weight="bold"
          htmlFor={inputId}
        >
          {label}
        </Text>
      )}

      <FilterControl
        id={inputId}
        className="selector-control"
        ref={setTarget}
        prepend={icon}
        readOnly
        open={show}
        size={size}
        variant={variant}
        value={displayValue || ''}
        onClick={() => {
          if (disabled) return;
          // If shown, hide and save filters
          if (show) handleOverlayHide();

          // Toggle show
          setShow((s) => !s);
          if (!show && onOpen) onOpen();
        }}
        disabled={disabled}
        hasError={!!errorText}
        {...inputProps}
      />

      {helperText && (
        <Text as="span" className="mt-2" size="sm1">
          {helperText}
        </Text>
      )}
      {errorText && (
        <Text as="span" className="mt-2" size="sm1" color="red400">
          {errorText}
        </Text>
      )}

      <Overlay
        className="selector-overlay tooltip"
        target={target}
        show={show}
        onHide={() => {
          handleOverlayHide();
          setShow(false);
          setSearch('');
          onSearch?.('');
        }}
        placement="bottom-start"
        fullMode={fullMode}
      >
        <Surface
          className={classNames(styles.surface, 'scroll-container')}
          padding="none"
          backgroundColor="grayLight3"
          style={{ maxHeight: 250, overflowY: 'auto' }}
          ref={viewportRef}
        >
          <div
            className={classNames('px-3 pb-3', {
              'pt-0': withSearch,
              'pt-3': !withSearch,
            })}
          >
            {withSearch && (
              <SearchBar
                className={styles.searchbar}
                placeholder="Pesquisar"
                value={controlledSearch || search}
                size="sm"
                onChange={(e) => {
                  if (!controlledSearch) {
                    setSearch(e.target.value);
                  }
                  onSearch?.(e.target.value);
                }}
                showIcon={false}
                ref={searchBarRef}
              />
            )}

            {/* Render a radio option menu */}
            {loading && <Spinner size="sm" />}

            {!loading && withSearch && filteredOptions.length === 0 && (
              <Text size="sm2" color="blackLight">
                Nenhum dado encontrado
              </Text>
            )}

            {!loading && (
              <>
                {type === 'radio' && (
                  <ViewportList
                    viewportRef={viewportRef}
                    items={filteredOptions}
                  >
                    {(opt, idx, arr) => (
                      <Radio
                        key={opt.key ?? opt.id}
                        className={classNames(styles.option, {
                          last: idx === arr.length - 1,
                        })}
                        name={name}
                        label={opt.label}
                        value={opt.id}
                        checked={selected === opt.id}
                        onChange={handleChange}
                        ellipsize={false}
                      />
                    )}
                  </ViewportList>
                )}

                {/* Render a checkbox option menu */}
                {type === 'checkbox' && (
                  <ViewportList
                    viewportRef={viewportRef}
                    items={filteredOptions}
                  >
                    {(opt, idx, arr) => (
                      <Checkbox
                        key={opt.key ?? opt.id}
                        className={classNames(styles.option, {
                          last: idx === arr.length - 1,
                        })}
                        name={`${name}-${idx}`}
                        label={opt.label}
                        value={opt.id}
                        checked={selected.includes(opt.id)}
                        onChange={handleChange}
                        ellipsize={false}
                      />
                    )}
                  </ViewportList>
                )}

                {/* Render a checkbox option menu */}
                {type === 'select' && (
                  <ViewportList
                    viewportRef={viewportRef}
                    items={filteredOptions}
                  >
                    {(opt) => (
                      <DropdownItem
                        key={opt.key ?? opt.id}
                        onClick={() => handleSelect(opt.id)}
                        ellipsize={false}
                      >
                        {opt.label}
                      </DropdownItem>
                    )}
                  </ViewportList>
                )}
              </>
            )}
          </div>
        </Surface>
      </Overlay>
    </div>
  );
};

export default MultipleSelectFilter;
