import * as React from 'react';
import {
  ColumnInstance,
  ColumnGroupInterface,
  useTable,
  UseTableOptions,
  UseTableCellProps,
  useExpanded,
  TableInstance,
  TableState,
  usePagination,
  UsePaginationOptions,
  UsePaginationInstanceProps,
  useSortBy,
  UseSortByOptions,
  UseSortByColumnProps,
  UseSortByState,
  useFlexLayout,
  useResizeColumns,
  UseResizeColumnsOptions,
  UseResizeColumnsColumnProps,
  UsePaginationState,
  HeaderGroup,
  PluginHook,
} from 'react-table';
import { useIsFirstRender } from '@quality24/react-hooks';

import { useSticky } from 'react-table-sticky';
import classNames from 'classnames';

import { useMediaQuery } from 'react-responsive';
import {
  TableDatum,
  TableColumn,
  TableOptions,
  FetchDataArgument,
} from '../types';

import Text from '../../../atoms/Text';
import TableSortIcon from '../../../atoms/TableSortIcon';
import EmptyTableComponent from '../EmptyTableComponent';
import DataTablePagination from '../../../molecules/DataTablePagination';
import Spinner from '../../../atoms/Spinner';
import { getHiddenColumnIds } from '../utils/columnUtils';

import styles from '../DataTable.module.scss';
import theme from '../../../../styles/theme';

interface DataTableCellProps extends UseTableCellProps<TableDatum> {
  column: ColumnInstance<TableDatum> & { className?: string };
}

export interface Props<T extends TableDatum>
  extends React.HTMLAttributes<HTMLElement> {
  tableId: string;
  columns: Array<TableColumn<T>>;
  loading?: boolean;
  data: Array<TableDatum>;
  getCellProps?: (cell: DataTableCellProps) => Record<'className', string>;
  // Pagination options
  pagination?: boolean;
  autoPagination?: boolean;
  entryCount?: number;
  pageCount?: number;
  totalCount?: number;
  fetchData?: (arg: FetchDataArgument) => void;
  // Sorting options
  manualSortBy?: boolean;
  initialSortBy?: Array<{ id: string; desc?: boolean }>;
  onChangeSortBy?: (arg: Array<{ id: string; desc?: boolean }>) => void;
  // Table options
  options?: TableOptions;
  // Translation
  i18n?: {
    emptyText: string;
  };
  append?: React.ReactNode;
  // Render
  isCompact?: boolean;
}

// Class interfaces

interface DataTableState
  extends TableState<TableDatum>,
    UsePaginationState<TableDatum>,
    UseSortByState<TableDatum> {}

interface DataTableHeaderGroup
  extends HeaderGroup<TableDatum>,
    UseSortByColumnProps<TableDatum>,
    UseResizeColumnsColumnProps<TableDatum> {
  headers: Array<DataTableHeaderGroup>;
  className?: string;
  headerClassName?: string;
}

interface DataTableInstanceProps
  extends TableInstance<TableDatum>,
    UsePaginationInstanceProps<TableDatum> {
  state: DataTableState;
  headerGroups: Array<DataTableHeaderGroup>;
  footerGroups: Array<DataTableHeaderGroup>;
}

interface DataTableOptions
  extends UseTableOptions<TableDatum>,
    UsePaginationOptions<TableDatum>,
    UseSortByOptions<TableDatum>,
    UseResizeColumnsOptions<TableDatum> {
  state: DataTableState;
}

/**
 * <DataTable> component
 */
export const DataTable = <T extends TableDatum>({
  tableId,
  className: tableClassName,
  loading,
  columns,
  data,
  totalCount = data.length,
  getCellProps,
  // Pagination options
  pagination,
  autoPagination = true,
  pageCount: controlledPageCount,
  fetchData = () => null,
  // Sorting options
  manualSortBy = false,
  initialSortBy = [],
  onChangeSortBy,
  // Table options
  options = {},
  // Translation
  i18n = { emptyText: 'Nenhum dado foi encontrado' },
  append = null,
  // Render
  isCompact = false,
  ...htmlElementProps
}: React.PropsWithChildren<Props<T>>): React.ReactElement => {
  // Check if pagination should be displayed.
  // Pagination should be displayed in the following cases:
  // - `pagination` is true
  // - `pagination` was not defined and data.length > 10
  const hasPagination = React.useMemo(() => {
    if (pagination) return true;
    if (pagination === undefined && data.length > 10) return true;
    return false;
  }, [pagination, data.length]);

  const isLargeScreen = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.sm})`,
  });

  const plugins = [
    useFlexLayout,
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns,
    isLargeScreen ? useSticky : undefined,
  ].filter((plugin) => plugin !== undefined) as Array<PluginHook<TableDatum>>;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { sortBy, pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // default column size
      defaultColumn: { minWidth: 50 },
      // Sort definitions
      manualSortBy,
      // Pass our hoisted table state
      initialState: {
        pageIndex: 0,
        pageSize: hasPagination ? 10 : data.length,
        sortBy: initialSortBy,
        hiddenColumns: getHiddenColumnIds(columns),
      },
      // prevent table state from automatically resetting when data changes
      // Ref.: https://react-table.tanstack.com/docs/faq#how-do-i-stop-my-table-state-from-automatically-resetting-when-my-data-changes
      autoResetPage: false,
      // Tell the usePagination hook that we'll handle our own data fetching
      // This means we'll also have to provide our own pageCount.
      manualPagination: !autoPagination,
      pageCount: controlledPageCount,
      // User defined table options
      ...options,
    } as DataTableOptions,
    ...plugins,
  ) as DataTableInstanceProps;

  // Store new sort state in reducer and call API to fetch new data from server
  React.useEffect(() => {
    if (!onChangeSortBy) return;
    onChangeSortBy(sortBy);
  }, [onChangeSortBy, sortBy]);

  const isFirstRender = useIsFirstRender();

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    if (isFirstRender || autoPagination || !pagination) return;
    fetchData({ pageIndex, pageSize });
  }, [
    fetchData,
    isFirstRender,
    autoPagination,
    pagination,
    pageIndex,
    pageSize,
  ]);

  // Navigate to first page when page count changes and the current page is out of bounds
  React.useEffect(() => {
    if (controlledPageCount && pageIndex >= controlledPageCount) {
      gotoPage(0);
    }
  }, [controlledPageCount, gotoPage, pageIndex]);

  // Check if table has any footer definition
  const hasFooter = React.useMemo(
    () =>
      columns
        .flatMap((column) =>
          (column as ColumnGroupInterface<T>).columns
            ? (column as ColumnGroupInterface<T>).columns
            : column,
        )
        .some((column) => column.Footer),
    // There is currently a bug with eslint-plugin-react-hooks v1
    // and typescript-eslint v4.0 in which the dependency array shows an
    // error for missing dependency on the generic type parameter
    // Ref.: https://github.com/facebook/react/issues/19808
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns],
  );

  const footerGroups = headerGroups.slice().reverse();

  // Filter only footerGroups with at least 1
  const filteredFooterGroups = footerGroups.filter((group) =>
    group.headers.every((column) => !!column.Footer),
  );

  // Get table className
  const tableCls = classNames('table sticky', tableClassName);

  const pageRange = React.useMemo(() => {
    const pageStart = pageIndex * pageSize;
    const possiblePageEnd = pageStart + pageSize;
    return `${pageStart + 1}-${Math.min(totalCount || 0, possiblePageEnd)}`;
  }, [pageIndex, pageSize, totalCount]);

  const paginationText = isCompact
    ? `${pageRange} de ${Math.max(totalCount || 0, data.length)}`
    : `Mostrando ${pageRange} de ${Math.max(
        totalCount || 0,
        data.length,
      )} resultados`;

  // Render the UI
  return (
    <>
      <div className={styles.wrapper} {...htmlElementProps}>
        <div
          id={tableId}
          className={classNames(tableCls, styles.table)}
          role="table"
          {...getTableProps()}
        >
          <div className={styles.thead} role="rowgroup">
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className={styles.tr}
                role="row"
              >
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        title: column.canSort ? 'Clique para ordenar' : '',
                      }),
                    )}
                    className={classNames(styles.th, column.headerClassName)}
                    role="columnheader"
                    data-sortable={column.canSort}
                  >
                    <Text as="span" size="sm2" weight="bold">
                      {column.render('Header')}
                    </Text>

                    <span className={styles.sorter}>
                      {column.canSort && (
                        <TableSortIcon
                          isSorted={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          disabled={!column.canSort}
                        />
                      )}
                    </span>
                    {column.canResize !== false && (
                      <div
                        {...column.getResizerProps({
                          // Prevent resizer from triggering the sort
                          onClick: (e: React.MouseEvent) => e.stopPropagation(),
                        })}
                        className={`${styles.resizer} ${
                          column.isResizing ? styles.isResizing : ''
                        }`}
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div
            {...getTableBodyProps()}
            className={styles.tbody}
            role="rowgroup"
          >
            {loading && (
              <div className={classNames(styles.tr, styles.loading)} role="row">
                <Spinner className={styles.spinner} />
              </div>
            )}

            {page.length === 0 && !loading && (
              <div className={classNames(styles.tr, styles.empty)} role="row">
                <EmptyTableComponent mainText={i18n.emptyText} />
              </div>
            )}

            {/* Table rows */}
            {!loading &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <div
                    {...row.getRowProps()}
                    className={classNames(styles.tr)}
                    role="row"
                  >
                    {row.cells.map((cell: DataTableCellProps) => (
                      <div
                        {...cell.getCellProps({
                          className: classNames(
                            styles.td,
                            cell.column.className,
                            getCellProps && getCellProps(cell).className,
                          ),
                        })}
                      >
                        <span title={cell.value} className={styles.cell}>
                          {cell.render('Cell')}
                        </span>
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>

          {/* Table Footer */}
          {hasFooter && (
            <div className={styles.tfoot} role="rowgroup">
              {filteredFooterGroups.map((footerGroup) => (
                <div
                  {...footerGroup.getHeaderGroupProps()}
                  className={styles.tr}
                  role="row"
                >
                  {footerGroup.headers.map((column) => (
                    <div
                      {...column.getHeaderProps()}
                      className={styles.td}
                      role="cell"
                    >
                      <Text as="p" size="sm2" weight="bold">
                        {column.render('Footer')}
                      </Text>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}

          {append}
        </div>
      </div>

      {/* Table Pagination */}
      {hasPagination && !!totalCount && totalCount > 0 && (
        <DataTablePagination
          label={paginationText}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageSize={pageSize}
          currentPage={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          isCompact={isCompact}
        />
      )}
    </>
  );
};

export default DataTable;
