import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import styles from './EmptyTableComponent.module.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  mainText?: string;
  helperText?: string;
}

const EmptyTableComponent: React.FunctionComponent<Props> = ({
  className,
  style,
  mainText = 'Nenhum dado foi encontrado.',
  helperText,
}) => (
  <div className={classNames(styles.wrapper, className)} style={style}>
    <FontAwesomeIcon className={styles.icon} icon={faMagnifyingGlass} />
    <div className={styles.text}>
      <div className={styles['main-text']}>{mainText}</div>
      {helperText && <div className={styles['helper-text']}>{helperText}</div>}
    </div>
  </div>
);

export default EmptyTableComponent;
