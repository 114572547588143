import * as React from 'react';
import classNames from 'classnames';

import { useSidebar } from '../../organisms/Sidebar';

import styles from './ManagementTemplate.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

/**
 * <ContentSection> component
 */
export const ContentSection: React.FunctionComponent<Props> = ({
  className,
  children,
  ...htmlElementProps
}) => {
  const { collapsed } = useSidebar();

  return (
    <div
      className={classNames(styles['content-section'], className, {
        'sidebar-collapsed': collapsed,
      })}
      {...htmlElementProps}
    >
      {children}
    </div>
  );
};

export default ContentSection;
