import React from 'react';

import { FrontEndError } from '@quality24/error-codes';
import authContext, { DashboardAuthContext } from './context';
import { Authenticator } from '../services/Authenticator';
import { RefreshSession } from '../services/tokenRefresh';

/** Storage for additional login data */
const STORAGE_KEY = 'additional-login-data';

const getFromStorage = <T extends Record<string, unknown>>(): T => {
  const valueInStorage = window.localStorage.getItem(STORAGE_KEY);
  return valueInStorage ? JSON.parse(valueInStorage) : {};
};

const saveToStorage = (v: Record<string, unknown>) => {
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(v));
};

const clearStorage = () => {
  window.localStorage.clear();
};

export interface Props {
  loginUri: string;
  redirectUri?: string;
  children: React.ReactNode;
}

/**
 * <DashboardAuthProvider> component
 */
const DashboardAuthProvider: React.FunctionComponent<Props> = ({
  loginUri,
  redirectUri = window.location,
  children,
}) => {
  const [userData, setUserData] = React.useState<Record<string, unknown>>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const [additionalData, setAdditionalData] = React.useState<
    Partial<Record<string, unknown>>
  >(getFromStorage<Record<string, unknown>>());

  // Build the login URL
  const clientId = React.useMemo(() => Authenticator.getClientId(), []);
  const loginUrl = React.useMemo(
    () =>
      encodeURI(
        `${loginUri}/login?clientId=${clientId}&redirect_uri=${redirectUri}`,
      ),
    [clientId, loginUri, redirectUri],
  );

  /**
   * Signs user in. Redirect to login page
   */
  const signIn = React.useCallback(() => {
    window.location.assign(loginUrl);
  }, [loginUrl]);

  /**
   * Signs user out
   */
  const signOut = React.useCallback(
    (code?: FrontEndError) => {
      clearStorage();
      // Redirect user to login
      const to = encodeURI(
        `${loginUri}/logout?clientId=${clientId}&redirect_uri=${redirectUri}${
          code ? `&code=${code}` : ''
        }`,
      );
      window.location.assign(to);
    },
    [clientId, loginUri, redirectUri],
  );

  // Checks if clientId is defined in the query and
  // if user is already authenticated
  React.useEffect(() => {
    const checkSession = async () => {
      try {
        const currentSession = await Authenticator.currentSession();
        setAuthenticated(true);
        setUserData(currentSession.getIdToken().payload);
      } catch (err) {
        // User not authenticated
        // eslint-disable-next-line no-console
        console.info('Redirecting user to login');
        setAuthenticated(false);
        setUserData(undefined);
        signIn();
      } finally {
        setLoading(false);
      }
    };

    checkSession();
  }, [signIn]);

  // Checks and refresh token (every 1s by default)
  React.useEffect(() => {
    if (!authenticated) {
      return () => null;
    }

    RefreshSession.startAutomaticRefresh({
      onError: () => setAuthenticated(false),
    });
    return () => RefreshSession.stopAutomaticRefresh();
  }, [authenticated]);

  /**
   * Updates the additional data state
   */
  const updateAdditionalData = React.useCallback((data) => {
    setAdditionalData((old) => {
      const value = { ...old, ...data };
      saveToStorage(value);
      return value;
    });
  }, []);

  /**
   * Prepare the auth value
   */
  const providerValue: DashboardAuthContext = React.useMemo(
    () => ({
      loading,
      isAuthenticated: authenticated,
      userData: { ...(userData || {}), ...(additionalData || {}) },
      loginUrl,
      setAuthenticated,
      signIn,
      signOut,

      // Additional data handlers
      addData: updateAdditionalData,
      updateData: (k, v) => updateAdditionalData({ [k]: v }),
      removeData: (k) => updateAdditionalData({ [k]: undefined }),
    }),
    [
      loading,
      authenticated,
      userData,
      additionalData,
      loginUrl,
      signIn,
      signOut,
      updateAdditionalData,
    ],
  );

  return (
    <authContext.Provider value={providerValue}>
      {children}
    </authContext.Provider>
  );
};

export default DashboardAuthProvider;
