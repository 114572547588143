import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Connection } from '@quality24/typings';
import { Service } from '@quality24/inpatient-typings';
import { useAccountIdentity } from './useAccountIdentity';
import { UseAllowedResourceFn } from './types';

export const GET_ALLOWED_SERVICES = gql`
  query GetAllowedServices(
    $includedServiceIds: [UUID!]
    $excludedServiceIds: [UUID!]
  ) {
    services: patientCallReasons(
      orderBy: ID_ASC
      filter: {
        or: [{ id: { in: $includedServiceIds, notIn: $excludedServiceIds } }]
      }
    ) {
      nodes {
        id
        reason
      }
    }
  }
`;

export interface AllowedServicesQueryResult {
  services: Connection<Pick<Service, 'id' | 'reason'>>;
}

/**
 * Hook Allowed Services
 * get list of items and ids released by `permission`
 *
 * @deprecated Use `useAllowedResources` instead
 *
 * @param userId
 * @param permission
 * @returns
 */
const useAllowedServices: UseAllowedResourceFn<
  Pick<Service, 'id' | 'reason'>
> = (userId, permission) => {
  const { getResourceAccess } = useAccountIdentity(userId);

  const servicePermission = React.useMemo(
    () => getResourceAccess('service', permission),
    [getResourceAccess, permission],
  );

  const { data, loading, error } = useQuery<AllowedServicesQueryResult>(
    GET_ALLOWED_SERVICES,
    {
      skip: servicePermission.type === 'none',
      variables: {
        includedServiceIds:
          servicePermission.type === 'allowlist'
            ? servicePermission.list
            : null,
        excludedServiceIds:
          servicePermission.type === 'denylist' ? servicePermission.list : null,
      },
    },
  );

  const allowedServices = React.useMemo(() => {
    if (data?.services && !loading && !error) {
      return {
        items: data.services.nodes.map((service) => service),
        ids: data.services.nodes.map((service) => service.id),
        isEvery: servicePermission.type === 'all',
      };
    }
    return { items: [], ids: [], isEvery: false };
  }, [data?.services, error, loading, servicePermission]);

  return React.useMemo(
    () => ({ ...allowedServices, loading, error }),
    [allowedServices, error, loading],
  );
};

export default useAllowedServices;
