import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Connection } from '@quality24/typings';
import {
  AllowedResourceAlias,
  AllowedResourceInput,
  DynamicResourceRecord,
  MinimalResource,
  AllowedResourcesPayload,
  UseAllowedResources,
} from './types';
import {
  getDepartmentsFragment,
  getFloorFragment,
  getServiceFragment,
  getServiceGroupFragment,
  getSurveyFragment,
  getUnitFragment,
} from './fragments';
import useAccountIdentity from './useAccountIdentity';

function isEmptyArray(array: unknown) {
  return Array.isArray(array) && array.length === 0;
}

const generateGraphqlVariables = (resource: AllowedResourceInput) => {
  const variables = [
    `
    $skip_${resource.alias}: Boolean!
    $included_${resource.alias}: [UUID!]
    $excluded_${resource.alias}: [UUID!]
    `,
  ];

  // For the resource Floor, one more HospitalUnit variable is needed,
  //   so the function adds the extra line in this special case
  if (resource.name === 'loc:level') {
    variables.push(
      `
    $included_unit_${resource.alias}: [UUID!]
    $excluded_unit_${resource.alias}: [UUID!]
      `,
    );
  }
  return variables.join(' ');
};

function choiceFragment(resource: AllowedResourceInput) {
  switch (resource.name) {
    case 'loc:building':
      return getUnitFragment(resource.alias, resource.columns);
    case 'loc:level':
      return getFloorFragment(resource.alias, resource.columns);
    case 'survey':
      return getSurveyFragment(resource.alias, resource.columns);
    case 'service':
      return getServiceFragment(resource.alias, resource.columns);
    case 'serviceGroup':
      return getServiceGroupFragment(resource.alias, resource.columns);
    case 'department':
      return getDepartmentsFragment(resource.alias, resource.columns);
    default:
      return '';
  }
}

export const getAllowedResourcesQuery = (
  resources: AllowedResourceInput[],
) => gql`
    query GetAllowedResourcesQuery(
      ${resources.map(generateGraphqlVariables).join(' ')}
    ) {
      ${resources.map(choiceFragment).join(' ')}
    }
  `;

const useAllowedResources: UseAllowedResources<AllowedResourcesPayload> = (
  userId,
  resources,
) => {
  const { getResourceAccess: getIdentityResourceAccess, loading: accLoading } =
    useAccountIdentity(userId);

  const getResourceAccess = React.useCallback(
    (resource: MinimalResource) =>
      getIdentityResourceAccess(resource.name, resource.permission),
    [getIdentityResourceAccess],
  );

  const queryVariables = React.useMemo(
    () =>
      resources?.reduce((variables, resource) => {
        const permission = resource && getResourceAccess(resource);

        if (!permission) return variables;

        const includedAndExcluded = {
          [`skip_${resource?.alias}`]: permission.type === 'none',

          [`included_${resource?.alias}`]:
            permission.type === 'allowlist' ? permission.list : null,
          [`excluded_${resource?.alias}`]:
            permission.type === 'denylist' ? permission.list : null,
        };

        if (resource?.name === 'loc:level') {
          const unitResource: MinimalResource = {
            name: 'loc:building',
            permission: resource.permission,
          };

          const unitPermission = getResourceAccess(unitResource);

          const extraIncludedAndExcluded = {
            [`skip_${resource?.alias}`]:
              includedAndExcluded[`skip_${resource?.alias}`] ||
              unitPermission.type === 'none',

            [`included_unit_${resource?.alias}`]:
              unitPermission.type === 'allowlist' ? unitPermission.list : null,

            [`excluded_unit_${resource?.alias}`]:
              unitPermission.type === 'denylist' ? unitPermission.list : null,
          };

          return {
            ...variables,
            ...includedAndExcluded,
            ...extraIncludedAndExcluded,
          };
        }

        return { ...variables, ...includedAndExcluded };
      }, {}) || {},
    [getResourceAccess, resources],
  );

  const { data, loading, error } = useQuery<
    Record<AllowedResourceAlias, Connection<AllowedResourcesPayload>>
  >(getAllowedResourcesQuery(resources), {
    skip: accLoading || isEmptyArray(resources) || false,
    variables: queryVariables,
  });

  const formattedResourcesResponse = React.useMemo(
    () =>
      resources?.reduce<DynamicResourceRecord<AllowedResourcesPayload>>(
        (response, resource) => {
          const { alias } = resource;

          if (!data || !alias || !data[alias] || loading || error) {
            return response;
          }

          const permission = resource && getResourceAccess(resource);

          const getBuildingType = () => {
            const buildingResource: MinimalResource = {
              name: 'loc:building',
              permission: resource.permission,
            };

            const buildingPermission =
              resource && getResourceAccess(buildingResource);

            return buildingPermission?.type;
          };

          return {
            ...response,
            [alias]: {
              items: data[alias].nodes.map((item) => item),
              ids: data[alias].nodes.map((item) => item?.id),
              isEvery:
                resource?.name === 'loc:level'
                  ? getBuildingType() === 'all' && permission?.type === 'all'
                  : permission?.type === 'all',
            },
          };
        },
        {},
      ) || {},
    [data, loading, error, resources, getResourceAccess],
  );

  return React.useMemo(() => {
    return {
      resources: formattedResourcesResponse,
      loading: accLoading || loading,
      error,
    };
  }, [formattedResourcesResponse, accLoading, loading, error]);
};

export default useAllowedResources;
