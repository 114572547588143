import * as React from 'react';
import LoadingBar, { LoadingBarRef, IProps } from 'react-top-loading-bar';

export interface Props extends IProps {
  loading: boolean | undefined;
  color?: string;
}

/**
 * <PageLoader> component.
 */
const PageLoader: React.FunctionComponent<Props> = ({
  loading,
  color = '#214e92',
  ...props
}) => {
  // Page loader ref
  const ref = React.useRef<LoadingBarRef>(null);

  // Start page loader
  React.useEffect(() => {
    // Skip if loading undefined
    if (loading === undefined) return;

    if (loading) {
      ref.current?.continuousStart(0, 1000);
    } else {
      ref.current?.complete();
    }
  }, [loading]);

  return (
    <div role="progressbar">
      <LoadingBar color={color} ref={ref} {...props} />
    </div>
  );
};

export default PageLoader;
