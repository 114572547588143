import {
  InpatientIdentityAction,
  InpatientIdentityResource,
} from '@quality24/inpatient-typings';
import { useToast } from '@quality24/design-system';
import { useDashboardAuth } from '@quality24/react-auth';
import { useAccountIdentity } from '@quality24/react-components';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthResponsePayload } from '../@types/auth';

export interface Props {
  action?: InpatientIdentityAction;
  resources?: InpatientIdentityResource[];
  children: JSX.Element;
}

/**
 * <PrivateRoute> component
 */
const PrivateRoute: React.FunctionComponent<Props> = ({
  action,
  resources,
  children,
}) => {
  const location = useLocation();
  const { error } = useToast();

  const { isAuthenticated, userData, loading, signOut } =
    useDashboardAuth<AuthResponsePayload['userData']>();
  const { loading: loadingPermissions, hasPermission } = useAccountIdentity(
    userData.sub,
  );

  // Log out if user is not an admin
  React.useEffect(() => {
    if (!loading && userData['custom:role'] !== 'admin') {
      signOut();
      error('Você não tem permissão de acessar esse serviço');
    }
  }, [error, loading, signOut, userData]);

  // Return nothing until finish loading permissions
  if (loading || loadingPermissions) return null;

  // Test for authenticated user
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Test for the permission
  if (action && !hasPermission(action, resources)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default PrivateRoute;
