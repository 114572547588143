export interface RuntimeConfig {
  API_URL?: string;
  WS_URL?: string;
  COGNITO_USER_POOL_ID?: string;
  COGNITO_CLIENT_ID?: string;
  COGNITO_REGION?: string;
  AUTH_SERVICE_URI?: string;
}

export const runtimeConfig =
  (window as unknown as Record<'runtimeConfig', RuntimeConfig>).runtimeConfig ||
  {};

export default runtimeConfig;
