import { Auth } from '@aws-amplify/auth';

export interface RefreshSessionOptions {
  checkPeriod?: number;
  preemptiveInterval?: number;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export class RefreshSession {
  private static refreshOperation: number | null;

  private static preemptiveInterval: number | null;

  private static checkPeriod = 1000;

  private static onSuccess: RefreshSessionOptions['onSuccess'] | null = null;

  private static onError: RefreshSessionOptions['onError'] | null = null;

  /**
   * Configures hooks for operation status.
   * If called multiple times, will override the previous configuration
   * @param options
   */
  static configure(options: RefreshSessionOptions) {
    this.checkPeriod = options.checkPeriod || 1000;
    this.preemptiveInterval = options.preemptiveInterval || 5000;
    this.onSuccess = options.onSuccess;
    this.onError = options.onError;
  }

  /**
   * Immediately refresh the session
   */
  static async refresh(options?: RefreshSessionOptions) {
    try {
      await Auth.currentSession();
      if (this.onSuccess) {
        this.onSuccess();
      }

      // On success, we need to update the automatic refresh timer
      if (!this.refreshOperation) {
        this.startAutomaticRefresh(options);
      }
    } catch (err) {
      if (this.onError) {
        this.onError(err as Error);
      }
    }
  }

  /**
   * Sets an interval to check/refresh token manually
   */
  static startAutomaticRefresh(options?: RefreshSessionOptions) {
    if (this.refreshOperation !== null) {
      // eslint-disable-next-line no-console
      console.warn(
        'There was another refresh running. Did you call this method twice?',
      );
      this.stopAutomaticRefresh();
    }

    this.refreshOperation = window.setInterval(async () => {
      try {
        await Auth.currentSession();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Could not refresh user session');
        if (this.onError) {
          this.onError(err as Error);
        }
        if (options?.onError) {
          options.onError(err as Error);
        }
      }
    }, options?.checkPeriod || this.checkPeriod);
  }

  /**
   * Stops the automatic token refresh routine
   */
  static stopAutomaticRefresh() {
    if (this.refreshOperation !== null) {
      window.clearInterval(this.refreshOperation);
      this.refreshOperation = null;
    }
  }
}
