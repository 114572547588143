import { gql, useQuery } from '@apollo/client';
import { Connection } from '@quality24/typings';
import React from 'react';
import { useAccountIdentity } from './useAccountIdentity';
import { AllowedResource, UseAllowedResourcePayload } from './types';

export const GET_ALLOWED_FLOORS = gql`
  query GetAllowedFloors(
    $includedUnitIds: [UUID!]
    $excludedUnitIds: [UUID!]
    $includedFloorIds: [UUID!]
    $excludedFloorIds: [UUID!]
  ) {
    floors(
      orderBy: ORDER_ASC
      filter: {
        visible: { equalTo: true }
        unitId: { in: $includedUnitIds, notIn: $excludedUnitIds }
        id: { in: $includedFloorIds, notIn: $excludedFloorIds }
      }
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

export interface AllowedFloorsQueryResult {
  floors: Connection<UseAllowedResourcePayload>;
}

/**
 * Hook Allowed Floors
 * get list of items and ids released by `permission`
 *
 * @deprecated Use `useAllowedResources` instead
 *
 * @param userId
 * @param permission
 * @returns
 */
const useAllowedFloors = (
  userId: string,
  permission: string,
): AllowedResource<UseAllowedResourcePayload> => {
  const { getResourceAccess, loading: accLoading } = useAccountIdentity(userId);

  const unitPermission = React.useMemo(
    () => getResourceAccess('loc:building', permission),
    [getResourceAccess, permission],
  );
  const floorPermission = React.useMemo(
    () => getResourceAccess('loc:level', permission),
    [getResourceAccess, permission],
  );

  const { data, loading, error } = useQuery<AllowedFloorsQueryResult>(
    GET_ALLOWED_FLOORS,
    {
      skip:
        accLoading ||
        unitPermission.type === 'none' ||
        floorPermission.type === 'none',
      variables: {
        includedUnitIds:
          unitPermission.type === 'allowlist' ? unitPermission.list : null,
        excludedUnitIds:
          unitPermission.type === 'denylist' ? unitPermission.list : null,
        includedFloorIds:
          floorPermission.type === 'allowlist' ? floorPermission.list : null,
        excludedFloorIds:
          floorPermission.type === 'denylist' ? floorPermission.list : null,
      },
    },
  );

  const allowedFloors = React.useMemo(() => {
    if (data?.floors && !loading && !error) {
      return {
        items: data.floors.nodes.map((floor) => floor),
        ids: data.floors.nodes.map((floor) => floor.id),
        isEvery:
          unitPermission.type === 'all' && floorPermission.type === 'all',
      };
    }
    return { items: [], ids: [], isEvery: false };
  }, [data?.floors, error, loading, unitPermission, floorPermission]);

  return React.useMemo(
    () => ({ ...allowedFloors, loading, error }),
    [allowedFloors, error, loading],
  );
};

export default useAllowedFloors;
