import React from 'react';
import { FrontEndError } from '@quality24/error-codes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DashboardAuthContext<T = Record<string, any>> {
  loading: boolean;
  isAuthenticated: boolean;
  userData: T;
  loginUrl: string;
  signIn: () => void;
  signOut: (code?: FrontEndError) => void;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  addData: (d: Partial<T>) => void;
  removeData: (k: string) => void;
  updateData: (k: string, newValue: unknown) => void;
}

// The initial authorization context
export const context = React.createContext<DashboardAuthContext>({
  loading: false,
  isAuthenticated: false,
  userData: {},
  loginUrl: '',
  signIn: async () => {},
  signOut: async () => {},
  setAuthenticated: () => null,
  addData: () => {},
  removeData: () => {},
  updateData: () => {},
});

export default context;
