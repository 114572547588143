/* eslint-disable no-console */
import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { Authenticator } from '@quality24/react-auth';

import runtimeConfig from '../config';
import subscriptionClient from './websocket';
import { headers as projectHeaders } from './http';

// //////////////////////////////////////////////
// Cache definitions
// //////////////////////////////////////////////

// Create cache
const cache = new InMemoryCache();

/**
 * Resets the apollo-client cache
 */
export const resetCache = async (): Promise<void> => {
  await cache.reset();
};

// //////////////////////////////////////////////
// Custom fetch definition
// //////////////////////////////////////////////

/**
 * This fetch is passed to the BatchHttpLink (apollo link)
 * for handling re-authorization.
 */

const fetcher = (uri: RequestInfo | URL, options: RequestInit | undefined) =>
  fetch(uri, {
    ...options,
    headers: { ...options?.headers, ...projectHeaders },
  }).then((response) => response);

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (error && 'statusCode' in error) {
          console.error(error);
        }
      });
    }

    if (networkError && 'statusCode' in networkError) {
      // Forbidden graphQL error inside Apollo response. Happens when authentication is not valid.
      if (networkError.statusCode === 403) {
        return;
      }
      // Unauthorized graphQL error inside Apollo response. Happens on expired token.
      if (networkError.statusCode === 401) {
        // Retry previous operation
        const prevContext = operation.getContext();
        const newContext = {
          ...prevContext,
        };

        operation.setContext(newContext);

        forward(operation);
      }
    }
  },
);

// //////////////////////////////////////////////
// Link definitions
// //////////////////////////////////////////////

// Create an Batch HTTP link
const httpLink = new BatchHttpLink({
  uri: runtimeConfig.API_URL || process.env.REACT_APP_API_URL,
  fetch: fetcher,
  batchInterval: 20,
});

// Create the HTTP auth middleware
const authLink = setContext(async (_ignore, { headers }) => {
  const session = await Authenticator.currentSession();
  if (!session || !session.getIdToken()) {
    return headers;
  }

  return {
    ...headers,
    headers: {
      authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    },
  };
});

// Create WebSocket link
const wsLink = new GraphQLWsLink(subscriptionClient);

// Concatenate link
const link = split(
  // Split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  ApolloLink.from([errorLink, authLink, httpLink]),
);

// //////////////////////////////////////////////
// Client definition and subscription listeners
// //////////////////////////////////////////////

// Create the Apollo Client
const client = new ApolloClient({
  link,
  cache,

  // Set local storage resolvers
  defaultOptions: {
    watchQuery: { fetchPolicy: 'cache-and-network' },
  },
});

export default client;
