import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';

import Sidebar, { MenuGroup } from '../../organisms/Sidebar';
import { UserInformations } from '../../organisms';
import ContentSection from './ContentSection';

import styles from './ManagementTemplate.module.scss';
import 'react-toastify/dist/ReactToastify.css';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  menu: Array<MenuGroup>;
  userInformations?: UserInformations;
  onLogout: () => void;

  // Here we need it to be any so it is generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuItemAs?: React.ElementType<any>;

  hideNavbar?: boolean;
}

/**
 * <ManagementTemplate> component
 */
export const ManagementTemplate: React.FunctionComponent<Props> = ({
  className,
  children,
  menu,
  menuItemAs,
  hideNavbar,
  userInformations,
  onLogout,
  ...htmlElementProps
}) => (
  <Sidebar.ContextProvider>
    <div
      className={classNames(styles.wrapper, className)}
      {...htmlElementProps}
    >
      {!hideNavbar && (
        <Sidebar
          menu={menu}
          menuItemAs={menuItemAs}
          userInformations={userInformations}
          onLogout={onLogout}
        />
      )}

      <ContentSection>
        <section className={styles.content}>{children}</section>
        <ToastContainer autoClose={8000} />
      </ContentSection>
    </div>
  </Sidebar.ContextProvider>
);

export default ManagementTemplate;
