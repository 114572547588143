import * as React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import LoginPage from '../pages/LoginPage/LoginPage';
import ErrorBoundary from '../components/organisms/RouteErrorBoundary';
import AppContainer from '../App';
import PrivateRoute from './PrivateRoute';
import allGroups, { DashboardMenuGroup } from './groups';
import RootLayout from '../components/templates/RootLayout';

const getRoutesFromGroups = (
  groups: DashboardMenuGroup[],
  path: '/public/' | undefined = '/public/',
): RouteObject[] => {
  const routes: RouteObject[] = [];
  groups
    .flatMap((g) => [...g.items])
    .forEach((route) => {
      const routeParts = route.to.split(path)[1];

      if (!routeParts) {
        return;
      }

      routes.push({
        path: routeParts,
        lazy: route.lazy,
        ErrorBoundary,
      });
    });
  return routes;
};

export const router = [
  {
    element: <AppContainer />,
    children: [
      {
        path: '/',
        ErrorBoundary,
        children: [
          {
            path: '/public',
            element: <RootLayout />,
            children: [
              {
                index: true,
                lazy: () => import('./HomeRoute'),
              },
              // Dynamic routes based on groups
              ...getRoutesFromGroups(allGroups),
              // Fallback routes
              {
                path: '*',
                element: <Navigate to="/404" replace />,
              },
            ],
          },
          // General routes
          {
            path: '/login',
            element: <LoginPage />,
          },
          {
            path: '/unauthorized',
            element: <RootLayout />,
            children: [
              {
                index: true,
                lazy: async () => {
                  const { Component } = await import(
                    '../pages/UnauthorizedPage/UnauthorizedPage'
                  );
                  return {
                    Component: () => (
                      <PrivateRoute>
                        <Component />
                      </PrivateRoute>
                    ),
                  };
                },
              },
            ],
          },
          {
            path: '404',
            element: <RootLayout />,
            children: [
              {
                index: true,
                lazy: async () => {
                  const { Component } = await import(
                    '../pages/NotFoundPage/NotFoundPage'
                  );
                  return {
                    Component: () => (
                      <PrivateRoute>
                        <Component />
                      </PrivateRoute>
                    ),
                  };
                },
              },
            ],
          },
          {
            index: true,
            element: <Navigate to="login" replace />,
          },
          {
            path: '*',
            element: <Navigate to="login" replace />,
          },
        ],
      },
    ],
  },
] as RouteObject[];

export default router;
