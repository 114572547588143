import * as React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';

import { useTheme } from '../../../contexts/theme';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button';
import MultipleSelectFilter from '../../organisms/MultipleSelectFilter';

import styles from './DataTablePagination.module.scss';

interface PageButton {
  label: string | number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  key: string;
}

export interface Props extends React.HTMLAttributes<HTMLElement> {
  canPreviousPage?: boolean;
  canNextPage?: boolean;
  pageSize: number;
  pageCount: number;
  gotoPage: (pageNumber: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize?: (pageSize: number) => void;
  currentPage: number;
  label?: string;
  isCompact?: boolean;
}

/**
 * <DataTablePagination> component
 */
export const DataTablePagination: React.FunctionComponent<Props> = ({
  className,
  canPreviousPage: canPrevPage = false,
  canNextPage = false,
  pageSize,
  pageCount,
  currentPage,
  gotoPage,
  previousPage,
  nextPage,
  label,
  setPageSize,
  isCompact = false,
  ...htmlElementProps
}: Props) => {
  const { theme } = useTheme();

  const isLarge =
    useMediaQuery({
      query: `(min-width: ${theme.breakpoints.md})`,
    }) && !isCompact;

  const pageBtns = React.useMemo(() => {
    const groupBtn = {
      label: '...',
      className: styles.disabled,
    };

    const aroundCurrentPageRange = 1;
    const showFirstPagesRange = 1;
    const showLastPagesRange = 1;

    const btns = [...Array(pageCount).keys()]
      .filter((key) => isLarge || key === currentPage)
      .map((key) => ({
        key: uuid(),
        label: key + 1 < 10 ? `0${key + 1}` : `${key + 1}`,
        className: key === currentPage ? 'active' : '',
        onClick: () => gotoPage(key),
      }))
      .reduce((acc, curr, idx) => {
        if (
          idx < showFirstPagesRange || // Show first 2 pages
          pageCount - idx <= showLastPagesRange || // Show last 2 pages
          Math.abs(idx - currentPage) <= aroundCurrentPageRange // Show around current page
        ) {
          return [...acc, curr];
        }

        return acc.length > 0 &&
          acc[acc.length - 1].className === styles.disabled
          ? acc
          : [...acc, { ...groupBtn, key: uuid() }];
      }, [] as PageButton[]);

    return btns;
  }, [currentPage, gotoPage, pageCount, isLarge]);

  return (
    <div
      className={classNames(styles.wrapper, className)}
      {...htmlElementProps}
    >
      {isLarge && label && (
        <Text size="sm2" color="blackLight">
          {label}
        </Text>
      )}

      {/* DataTablePagination group */}
      <div className={styles['buttons-wrapper']}>
        <Button
          variant="link"
          onClick={previousPage}
          aria-label="Anterior"
          disabled={!canPrevPage}
        >
          {isLarge ? 'Anterior' : <FontAwesomeIcon icon={faChevronLeft} />}
        </Button>

        {pageBtns.map((btn) => (
          <Button
            variant={btn.className !== 'active' ? 'link' : 'default'}
            key={btn.key}
            onClick={btn.onClick}
            className={btn.className}
            style={btn.label === '...' ? { cursor: 'default' } : undefined}
          >
            {btn.label}
          </Button>
        ))}

        <Button
          variant="link"
          onClick={nextPage}
          aria-label="Próximo"
          disabled={!canNextPage}
        >
          {isLarge ? 'Próxima' : <FontAwesomeIcon icon={faChevronRight} />}
        </Button>
      </div>

      {/* Page size selector */}
      {setPageSize && (
        <MultipleSelectFilter
          type="select"
          name="pageSizeSelector"
          label={isLarge ? 'Linhas por página' : 'Linhas'}
          className={styles['page-size-selector']}
          options={[10, 20, 30, 40, 50].map((size) => ({
            id: size.toString(),
            label: size.toString(),
          }))}
          value={pageSize.toString()}
          onChange={(_name, value) => {
            setPageSize(parseInt(value as string, 10));
          }}
        />
      )}
    </div>
  );
};

export default DataTablePagination;
