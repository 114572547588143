import * as React from 'react';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import { IconProps } from '../../atoms/Icon';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import image from './not-found.svg';

export interface Props {
  className?: string;
  title?: string;
  subtitle?: string;
  actionTitle?: string;
  actionIcon?: IconProps['icon'];
  handleAction?: () => void;
}

/**
 * <NotFound> component.
 */
const NotFound: React.FunctionComponent<Props> = ({
  className,
  title,
  subtitle,
  actionTitle,
  actionIcon,
  handleAction,
}) => {
  return (
    <div
      className={classNames(
        className,
        'd-flex flex-column align-items-center text-center gap-4',
      )}
    >
      <Image
        alt="Ilutração de uma enfermeira com laptop sentada sobre um pote de remédios"
        src={image}
      />
      <div className="d-flex flex-column gap-2">
        <Heading as="h1" size="lg2" weight="bold" color="primary">
          {title || 'Página não encontrada'}
        </Heading>
        <Text size="lg2">{subtitle || 'Essa página não existe'}</Text>
      </div>
      <div className="d-flex gap-3">
        <Button
          variant="outline"
          size="lg"
          icon={faArrowLeft}
          onClick={() => {
            window.history.back();
          }}
        >
          <Text className="w-100 text-center" as="span" weight="bold">
            Voltar
          </Text>
        </Button>

        {actionTitle && handleAction && (
          <Button size="lg" icon={actionIcon} onClick={handleAction}>
            <Text className="w-100 text-center" as="span" weight="bold">
              {actionTitle}
            </Text>
          </Button>
        )}
      </div>
    </div>
  );
};

export default NotFound;
