import { faHome } from '@fortawesome/pro-regular-svg-icons';
import * as React from 'react';
import classNames from 'classnames';
import { Button, Icon, Text } from '../../atoms';

interface NavigateFunction {
  (delta: number, options?: { relative?: 'path'; replace?: boolean }): void;
  (path: string, options?: { relative?: 'path'; replace?: boolean }): void;
}

export interface BreadCrumbItem {
  key?: string;
  label: string;
  path: string;
  active?: boolean;
}

export interface Props extends React.HTMLAttributes<HTMLUListElement> {
  rootPath?: string;
  items: BreadCrumbItem[];
  navigate: NavigateFunction;
}

/**
 * <Breadcrumb> component
 */
const Breadcrumb: React.FunctionComponent<Props> = ({
  className,
  rootPath,
  items,
  navigate,
  ...htmlProps
}) => {
  return (
    <ul
      id="breadcrumb"
      className={classNames(className, 'd-flex')}
      {...htmlProps}
    >
      {rootPath && (
        <li className="d-flex flex-items-center">
          <Button variant="link" size="sm" onClick={() => navigate(rootPath)}>
            <Icon icon={faHome} color="primary" />
          </Button>
          <Text as="span" color="grayDark" size="sm1">
            /
          </Text>
        </li>
      )}
      {items.map((item, index) => (
        <li key={item.key || item.label} className="d-flex flex-items-center">
          <Button
            variant="link"
            size="sm"
            onClick={() => navigate(item.path, { relative: 'path' })}
            disabled={item.active}
          >
            {item.label}
          </Button>
          {items[index + 1] && (
            <Text as="span" color="grayDark" size="sm1">
              /
            </Text>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumb;
