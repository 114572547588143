import React from 'react';

/**
 * Returns true on mount, false for subsequent renders
 * @param {*} callback
 * @param {*} delay
 */
const useIsFirstRender = () => {
  const isFirstRenderRef = React.useRef(true);
  React.useEffect(() => {
    isFirstRenderRef.current = false;
  }, []);
  return isFirstRenderRef.current;
};

export default useIsFirstRender;
