import { Policy, PolicyStatement } from '@quality24/typings';
import { minimatch } from 'minimatch';
import { ProcessedPolicy } from './types';

const KEY_SEPARATOR = '|';

type TPolicy<
  TAction extends string = string,
  TResource extends string = string,
> = Pick<Policy<TResource, TAction>, 'version' | 'statements'>;

const getMapKey = <
  TAction extends string = string,
  TResource extends string = string,
>(
  resource: TResource,
  effect: PolicyStatement<TResource, TAction>['effect'] = 'Allow',
): `${TResource}${typeof KEY_SEPARATOR}${NonNullable<
  PolicyStatement['effect']
>}` => `${resource}${KEY_SEPARATOR}${effect}`;

/**
 * Merges multiple policies into a single policy
 * @param policies
 */
// eslint-disable-next-line import/prefer-default-export
export const mergePolicy = <
  TAction extends string = string,
  TResource extends string = string,
>(
  policies: TPolicy<TAction, TResource>[],
): ProcessedPolicy<TAction, TResource> => {
  const statements: Record<
    `${TResource}${typeof KEY_SEPARATOR}${NonNullable<
      PolicyStatement['effect']
    >}`,
    Array<TAction>
  > = {} as Record<
    `${TResource}${typeof KEY_SEPARATOR}${NonNullable<
      PolicyStatement['effect']
    >}`,
    Array<TAction>
  >;

  Object.values(policies.flatMap((p) => p.statements)).forEach((statement) => {
    const resources = Array.isArray(statement.resource)
      ? statement.resource
      : [statement.resource];
    resources.forEach((resource) => {
      // Initialize the statement resource map
      const key = getMapKey<TAction, TResource>(resource, statement.effect);
      if (!statements[key]) {
        statements[key] = [];
      }

      // If the current action contains the old one, replace
      // We are removing the duplicates during the last step
      statement.actions.forEach((action) => {
        let replacedSomething = false;
        for (let i = 0; i < statements[key].length; i += 1) {
          if (minimatch(statements[key][i], action)) {
            replacedSomething = true;
            statements[key][i] = action;
          } else if (minimatch(action, statements[key][i])) {
            // If old range contains new, ignore the current action
            replacedSomething = true;
          }
        }

        if (!replacedSomething) {
          statements[key].push(action);
        }
      });

      // Remove the duplicates
      statements[key] = [...new Set(statements[key])];
    });
  });

  return {
    version: '2023-01-01',
    statements: Object.entries(statements).map(([key, actions]) => {
      const [resource, effect] = key.split(KEY_SEPARATOR);
      return {
        effect: effect as NonNullable<PolicyStatement['effect']>,
        resource: resource as TResource,
        actions: actions as Array<TAction>,
      };
    }),
  };
};
