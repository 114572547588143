/* eslint-disable no-console */
import { Authenticator } from '@quality24/react-auth';
import { createClient } from 'graphql-ws';

import runtimeConfig from '../config';
import { headers as projectHeaders } from './http';

//
// WebSocket States
//

export enum WebSocketState {
  CONNECTING = WebSocket.CONNECTING,
  CONNECTED = WebSocket.OPEN,
  DISCONNECTING = WebSocket.CLOSING,
  DISCONNECTED = WebSocket.CLOSED,
}

export interface WebSocketStateObject {
  state: WebSocketState;
  lastConnection: Date | null;
}

/**
 * The default WebSocket state object
 */
export const defaultWebSocketState: WebSocketStateObject = {
  state: WebSocketState.DISCONNECTED,
  lastConnection: null,
};

/**
 * Updates the WebSocket last connection timestamp
 */
const setWebSocketState = (state: WebSocketState): void => {
  defaultWebSocketState.state = state;
  defaultWebSocketState.lastConnection = new Date();
};

/**
 * The WebSocket transport client
 */
const subscriptionClient = createClient({
  url: (runtimeConfig.WS_URL || process.env.REACT_APP_WS_URL) as string,
  keepAlive: 10000,
  connectionParams: async () => {
    const session = await Authenticator.currentSession();
    if (!session || !session.getIdToken()) {
      return { headers: projectHeaders };
    }
    return {
      headers: {
        authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
        ...projectHeaders,
      },
    };
  },

  // Event Listeners
  on: {
    connected: () => {
      console.info('WebSocket connected.');
      setWebSocketState(WebSocketState.CONNECTED);

      // Reset apollo store to refetch all active queries. This side-effect is suggested in
      // https://github.com/apollographql/apollo-client/issues/1831#issuecomment-311442700
      // subscriptionClient.reFetchObservableQueries();
    },
    closed: () => {
      console.info('WebSocket disconnected.');
      setWebSocketState(WebSocketState.DISCONNECTED);
    },
    error: () => {
      console.info('WebSocket error.');
    },
  },
});

/**
 * Forces the WebSocket connection to close.
 */
export const disconnectWebsocket = (): void => {
  subscriptionClient.dispose();
};

export default subscriptionClient;
