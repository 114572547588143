import 'moment/locale/pt-br';
import { ApolloProvider } from '@apollo/client';
import {
  ErrorBoundary,
  ModalContainer,
  ThemeProvider,
  ToastContainer,
} from '@quality24/design-system';
import { DashboardAuthProvider } from '@quality24/react-auth';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { MenuProvider } from './providers/menu';
import graphqlClient from './services/graphql';
import runtimeConfig from './config';

/**
 * <AppContainer> component
 * This component replaces the Outlet component with the route's element.
 * The element is rendered inside the providers which may depend on react-router-dom,
 * like AuthProvider that uses useNavigate.
 */
const AppContainer: React.FC = () => (
  <ThemeProvider>
    <ToastContainer autoClose={8000} />
    <DashboardAuthProvider
      loginUri={
        runtimeConfig.AUTH_SERVICE_URI ||
        process.env.REACT_APP_AUTH_SERVICE_URI ||
        ''
      }
    >
      <ApolloProvider client={graphqlClient}>
        <ErrorBoundary>
          <MenuProvider>
            <ModalContainer />
            <Outlet />
          </MenuProvider>
        </ErrorBoundary>
      </ApolloProvider>
    </DashboardAuthProvider>
  </ThemeProvider>
);

export default AppContainer;
