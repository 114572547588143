/* eslint-disable import/prefer-default-export */
import Cookies from 'js-cookie';

export class CookieStorage {
  // set item with the key
  static setItem(key: string, value: string): string {
    Cookies.set(key, value, {
      domain:
        process.env.NODE_ENV === 'production' ? 'quality24.com.br' : undefined,
      sameSite: 'strict',
      secure: true,
    });
    return value;
  }

  // get item with the key
  static getItem(key: string): string {
    return Cookies.get(key) || '';
  }

  // remove item with the key
  static removeItem(key: string): void {
    Cookies.remove(key);
  }

  // clear out the storage
  static clear(): void {
    const cookies = Cookies.get();
    Object.keys(cookies).forEach((k) => Cookies.remove(k));
  }
}
