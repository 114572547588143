import { MenuGroup } from '@quality24/design-system';
import React from 'react';

export interface MenuContext {
  menu: Array<MenuGroup>;
}

const menuContext = React.createContext<MenuContext>({
  menu: [],
});

export default menuContext;
