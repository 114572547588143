/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { faFilesMedical } from '@fortawesome/pro-regular-svg-icons';
import { MenuGroup, MenuItem } from '@quality24/design-system';
import { LazyRouteFunction, RouteObject } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

export type DashboardMenuItem = MenuItem & {
  /**
   * The identity policy permission
   */
  permission: string;
  /**
   * The lazy loading function
   */
  lazy: LazyRouteFunction<RouteObject>;
  /**
   * If routingOnly is true this item should not be rendered on the menu
   */
  routingOnly?: boolean;
};

export type DashboardMenuGroup = Omit<MenuGroup, 'items'> & {
  items: Array<DashboardMenuItem>;
};

export const groups: Array<DashboardMenuGroup> = [
  {
    id: 'group1',
    label: 'Administração',
    items: [
      {
        id: 'item1',
        icon: faFilesMedical,
        children: 'Organizações',
        to: '/public/organizations',
        permission: 'admin:dashboard:page:organizations:view',
        lazy: async () => {
          const { Component } = await import(
            '../pages/OrganizationListPage/OrganizationListPage'
          );
          return {
            Component: () => (
              <PrivateRoute action="admin:dashboard:page:organizations:view">
                <Component />
              </PrivateRoute>
            ),
          };
        },
      },
      {
        id: 'item1.1',
        icon: faFilesMedical,
        children: 'Organização',
        to: '/public/organizations/:id',
        routingOnly: true,
        permission: 'admin:dashboard:page:organizations:view',
        lazy: async () => {
          const { Component } = await import(
            '../pages/OrganizationPage/OrganizationPage'
          );
          return {
            Component: () => (
              <PrivateRoute action="admin:dashboard:page:organizations:view">
                <Component />
              </PrivateRoute>
            ),
          };
        },
      },
      {
        id: 'item1.2',
        icon: faFilesMedical,
        children: 'Hospital',
        to: '/public/organizations/:id/hospitals/:hospitalId',
        routingOnly: true,
        permission: 'admin:dashboard:page:hospitals:view',
        lazy: async () => {
          const { Component } = await import(
            '../pages/HospitalPage/HospitalPage'
          );
          return {
            Component: () => (
              <PrivateRoute action="admin:dashboard:page:hospitals:view">
                <Component />
              </PrivateRoute>
            ),
          };
        },
      },
      {
        id: 'item1.3',
        icon: faFilesMedical,
        children: 'Solicitações',
        to: '/public/organizations/:id/hospitals/:hospitalId/services',
        routingOnly: true,
        permission: 'admin:dashboard:page:services:view',
        lazy: async () => {
          const { Component } = await import(
            '../pages/ServiceListPage/ServiceListPage'
          );
          return {
            Component: () => (
              <PrivateRoute action="admin:dashboard:page:services:view">
                <Component />
              </PrivateRoute>
            ),
          };
        },
      },
      {
        id: 'item1.4',
        icon: faFilesMedical,
        children: 'Pesquisas',
        to: '/public/organizations/:id/hospitals/:hospitalId/surveys',
        routingOnly: true,
        permission: 'admin:dashboard:page:surveys:view',
        lazy: async () => {
          const { Component } = await import(
            '../pages/SurveyListPage/SurveyListPage'
          );
          return {
            Component: () => (
              <PrivateRoute action="admin:dashboard:page:surveys:view">
                <Component />
              </PrivateRoute>
            ),
          };
        },
      },
      {
        id: 'item1.5',
        icon: faFilesMedical,
        children: 'Pesquisa',
        to: '/public/organizations/:id/hospitals/:hospitalId/surveys/:surveyId',
        routingOnly: true,
        permission: 'admin:dashboard:page:surveys:view',
        lazy: async () => {
          const { Component } = await import('../pages/SurveyPage/SurveyPage');
          return {
            Component: () => (
              <PrivateRoute action="admin:dashboard:page:surveys:view">
                <Component />
              </PrivateRoute>
            ),
          };
        },
      },
    ],
  },
];

export default groups;
