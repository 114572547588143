import classNames from 'classnames';
import * as React from 'react';

export interface Props<T extends string | number>
  extends React.HTMLAttributes<HTMLButtonElement> {
  value: T;
  selected?: boolean;
  onClick?: React.MouseEventHandler<Element>;
  children?: React.ReactNode;
}

const SelectionOption = <T extends string | number>({
  className,
  children,
  value,
  selected,
  onClick,
  ...htmlElementProps
}: React.PropsWithChildren<Props<T>>) => (
  <button
    type="button"
    className={classNames('option', className)}
    key={value}
    role="option"
    aria-selected={selected}
    aria-label={`${value}`}
    {...htmlElementProps}
    onClick={onClick}
  >
    {children}
  </button>
);

export default SelectionOption;
