import { minimatch } from 'minimatch';
import { ProcessedPolicy } from './types';

export type GetResourceAccessResult =
  | {
      type: 'all' | 'none';
    }
  | {
      type: 'allowlist' | 'denylist';
      list: Array<string>;
    };

/**
 * Retrieves a list of resource Ids that this policy has access to or true if a '*' is found.
 * Could be seen as the question: "Are there any resources that the user have the access permission X?"
 *
 * Example:
 * Calling `getResourceAccess('loc:floor')` could result in three scenarios:
 * 1. if policy contain resources 'loc:floor:1' and 'loc:floor:2', return ['1', '2']
 * 2. if policy contain resources '*' or 'loc:floor:*', return true
 * 3. if policy does not contain resources associated to the policy, return false
 */
// eslint-disable-next-line import/prefer-default-export
export const getResourceAccess = <
  TAction extends string = string,
  TResource extends string = string,
>(
  policy: ProcessedPolicy<TAction, TResource>,
  resourcePrefix: string,
  permission: TAction,
): GetResourceAccessResult => {
  // By default, all permissions are restricted
  if (!policy) return { type: 'none' };

  // filter statements by action
  const denyStatements: ProcessedPolicy<TAction, TResource>['statements'] = [];
  const allowStatements: ProcessedPolicy<TAction, TResource>['statements'] = [];
  policy.statements.forEach((st) => {
    /**
     * Checking if:
     * 1. statement.resource startsWith the resourcePrefix or is contained by any glob in the statement.resource
     * 2. some action, in the array of statement.actions, match the required permission
     */
    if (
      (minimatch(resourcePrefix, st.resource) ||
        st.resource.startsWith(`${resourcePrefix}:`)) &&
      st.actions.some((action) => minimatch(permission, action))
    ) {
      if (st.effect === 'Allow') {
        allowStatements.push(st);
      } else if (st.effect === 'Deny') {
        denyStatements.push(st);
      }
    }
  });
  if (denyStatements.length === 0 && allowStatements.length === 0) {
    return { type: 'none' };
  }

  // If we have no ALLOW statements, we are denying everything
  if (allowStatements.length === 0) {
    return { type: 'none' };
  }

  // Check if we are DENY everything
  const denyEverything = denyStatements.some(
    (st) => st.resource === '*' || st.resource === `${resourcePrefix}:*`,
  );
  if (denyEverything) {
    return { type: 'none' };
  }

  const allowEverything = allowStatements.some(
    (st) => st.resource === '*' || st.resource === `${resourcePrefix}:*`,
  );

  // If we have no DENY statements, check if we are allowing everything or specific data
  if (denyStatements.length === 0) {
    if (allowEverything) {
      return { type: 'all' };
    }
    return {
      type: 'allowlist',
      list: allowStatements.map((st) =>
        st.resource.replace(`${resourcePrefix}:`, ''),
      ),
    };
  }

  // If denying specific entries, return a denyList
  if (allowEverything) {
    return {
      type: 'denylist',
      list: denyStatements.map((st) =>
        st.resource.replace(`${resourcePrefix}:`, ''),
      ),
    };
  }

  // Else, allow and deny list does not overlap due to mergePolicy function result
  // so we are returning an allow list
  return {
    type: 'allowlist',
    list: allowStatements.map((st) =>
      st.resource.replace(`${resourcePrefix}:`, ''),
    ),
  };
};
