import * as React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import styles from './FilterControl.module.scss';

// Interfaces
// //////////////////////////

export interface Props extends React.HTMLAttributes<HTMLElement> {
  type?: 'text' | 'select';
  size?: 'sm' | 'md';
  variant?: 'default' | 'outline';
  prepend?: FontAwesomeIconProps['icon'];
  value?: string;
  placeholder?: string;
  open?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLElement>;
  hasError?: boolean;
}

/**
 * <FilterControl> component
 */
export const FilterControl = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      className,
      style,
      type = 'text',
      size = 'sm',
      value,
      variant,
      prepend,
      placeholder,
      open,
      disabled,
      readOnly,
      onChange,
      onClick,
      hasError = false,
      ...htmlElementProps
    },
    ref,
  ) => {
    const cls = classNames(styles['filter-control'], className, {
      disabled,
      'variant-outline': variant === 'outline',
      clickable: !!onClick,
      error: !!hasError,
    });

    return (
      <div
        ref={ref}
        className={cls}
        style={style}
        onClick={onClick}
        role="button"
        aria-hidden
        {...htmlElementProps}
      >
        <div className={classNames(styles['input-wrapper'], `size-${size}`)}>
          {prepend && (
            <FontAwesomeIcon className={styles.icon} icon={prepend} />
          )}

          <input
            id={id}
            type={type}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChange}
          />

          <FontAwesomeIcon
            className={styles.icon}
            icon={open ? faChevronUp : faChevronDown}
          />
        </div>
      </div>
    );
  },
);

export default FilterControl;
