import { gql, useQuery } from '@apollo/client';
import { Connection } from '@quality24/typings';
import React from 'react';

import { useAccountIdentity } from './useAccountIdentity';
import { AllowedResource, UseAllowedResourcePayload } from './types';

export const GET_ALLOWED_SERVICE_GROUPS = gql`
  query GetAllowedServiceGroups(
    $includedGroupIds: [UUID!]
    $excludedGroupIds: [UUID!]
  ) {
    groups: serviceGroups(
      orderBy: ID_ASC
      filter: {
        or: [{ id: { in: $includedGroupIds, notIn: $excludedGroupIds } }]
      }
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

export interface AllowedServiceGroupsQueryResult {
  groups: Connection<UseAllowedResourcePayload>;
}

/**
 * Hook Allowed ServiceGroups
 * get list of items and ids released by `permission`
 *
 * @deprecated Use `useAllowedResources` instead
 *
 * @param userId
 * @param permission
 * @returns
 */
const useAllowedServiceGroups = (
  userId: string,
  permission: string,
): AllowedResource<UseAllowedResourcePayload> => {
  const { getResourceAccess, loading: accLoading } = useAccountIdentity(userId);

  const groupPermission = React.useMemo(
    () => getResourceAccess('serviceGroup', permission),
    [getResourceAccess, permission],
  );

  const { data, loading, error } = useQuery<AllowedServiceGroupsQueryResult>(
    GET_ALLOWED_SERVICE_GROUPS,
    {
      skip: accLoading || groupPermission.type === 'none',
      variables: {
        includedGroupIds:
          groupPermission.type === 'allowlist' ? groupPermission.list : null,
        excludedGroupIds:
          groupPermission.type === 'denylist' ? groupPermission.list : null,
      },
    },
  );

  const allowedGroups = React.useMemo(() => {
    if (data?.groups && !loading && !error) {
      return {
        items: data.groups.nodes.map((group) => group),
        ids: data.groups.nodes.map((group) => group.id),
        isEvery: groupPermission.type === 'all',
      };
    }
    return { items: [], ids: [], isEvery: false };
  }, [data?.groups, error, loading, groupPermission]);

  return React.useMemo(
    () => ({ ...allowedGroups, loading: loading || accLoading, error }),
    [accLoading, allowedGroups, error, loading],
  );
};

export default useAllowedServiceGroups;
