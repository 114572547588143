import { gql, useQuery } from '@apollo/client';
import { Connection } from '@quality24/typings';
import React from 'react';

import { useAccountIdentity } from './useAccountIdentity';
import { AllowedResource, UseAllowedResourcePayload } from './types';

export const GET_ALLOWED_SURVEYS = gql`
  query GetAllowedSurveys(
    $includedSurveyIds: [UUID!]
    $excludedSurveyIds: [UUID!]
  ) {
    surveys(
      orderBy: NAME_ASC
      filter: {
        or: [{ id: { in: $includedSurveyIds, notIn: $excludedSurveyIds } }]
      }
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

export interface AllowedSurveysQueryResult {
  surveys: Connection<UseAllowedResourcePayload>;
}

/**
 * Hook Allowed Surverys
 * get list of items and ids released by `permission`
 *
 * @deprecated Use `useAllowedResources` instead
 *
 * @param userId
 * @param permission
 * @returns
 */
const useAllowedSurveys = (
  userId: string,
  permission: string,
): AllowedResource<UseAllowedResourcePayload> => {
  const { getResourceAccess, loading: accLoading } = useAccountIdentity(userId);

  const surveyPermission = React.useMemo(
    () => getResourceAccess('survey', permission),
    [getResourceAccess, permission],
  );

  const { data, loading, error } = useQuery<AllowedSurveysQueryResult>(
    GET_ALLOWED_SURVEYS,
    {
      skip: accLoading || surveyPermission.type === 'none',
      variables: {
        includedSurveyIds:
          surveyPermission.type === 'allowlist' ? surveyPermission.list : null,
        excludedSurveyIds:
          surveyPermission.type === 'denylist' ? surveyPermission.list : null,
      },
    },
  );

  const allowedUnits = React.useMemo(() => {
    if (data?.surveys && !loading && !error) {
      return {
        items: data.surveys.nodes.map((survey) => survey),
        ids: data.surveys.nodes.map((survey) => survey.id),
        isEvery: surveyPermission.type === 'all',
      };
    }
    return { items: [], ids: [], isEvery: false };
  }, [data?.surveys, error, loading, surveyPermission]);

  return React.useMemo(
    () => ({ ...allowedUnits, loading, error }),
    [allowedUnits, error, loading],
  );
};

export default useAllowedSurveys;
