import { Button, Spinner, Surface, Text } from '@quality24/design-system';
import { useDashboardAuth } from '@quality24/react-auth';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import classNames from 'classnames';
import styles from './LoginPage.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  className?: string;
}

/**
 * <LoginPage> component
 */
const LoginPage: React.FunctionComponent<Props> = ({
  className,
  ...htmlAttributes
}) => {
  const { loginUrl, isAuthenticated } = useDashboardAuth();

  // Redirect if user is already logged in
  if (isAuthenticated) {
    return <Navigate to="/public" />;
  }

  return (
    <div className={classNames(styles.wrapper, className)} {...htmlAttributes}>
      <Surface>
        <Spinner className="mb-5" size="sm" />
        <Text size="lg1" className="mb-5">
          Aguarde enquanto te redirecionamos para o login...
        </Text>

        <Text className="mb-2" size="sm1">
          Se nada acontecer, clique no botão abaixo
        </Text>
        <Button
          size="md"
          variant="link"
          onClick={() => window.location.assign(loginUrl)}
        >
          Ir para login
        </Button>
      </Surface>
    </div>
  );
};

export default LoginPage;
