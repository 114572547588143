import * as React from 'react';
import classNames from 'classnames';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import Text from '../Text';

import styles from './DropdownItem.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  icon?: FontAwesomeIconProps['icon'];
  disabled?: boolean;
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  ellipsize?: boolean;
}

export const DropdownItem: React.FunctionComponent<Props> = ({
  className,
  style,
  icon,
  children,
  disabled,
  onClick,
  ellipsize = true,
  ...htmlElementProps
}) => (
  <button
    type="button"
    className={classNames(styles.item, className, { disabled, ellipsize })}
    onClick={onClick}
    {...htmlElementProps}
  >
    {icon && <FontAwesomeIcon className={styles.icon} icon={icon} />}
    <Text as="span" size="sm2">
      {children}
    </Text>
  </button>
);

export default DropdownItem;
