import { ManagementTemplate } from '@quality24/design-system';
import { useDashboardAuth } from '@quality24/react-auth';
import { useStorage } from '@quality24/react-hooks';
import React, { useCallback } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import { useMenu } from '../../../providers/menu';
import { AuthResponsePayload } from '../../../@types/auth';

/**
 * <RootLayout> component.
 */
const RootLayout: React.FunctionComponent = () => {
  const { loading, signOut } =
    useDashboardAuth<AuthResponsePayload['userData']>();
  const { menu } = useMenu();
  const { retrieveItem } = useStorage();

  // const userInformations = {
  //   email: userData.email || '-',
  //   role: userData['custom:role'],
  // };

  const handleLogout = useCallback(() => {
    signOut();
  }, [signOut]);

  if (loading) return null;

  return (
    <ManagementTemplate
      menu={menu}
      menuItemAs={NavLink}
      // userInformations={userInformations}
      onLogout={handleLogout}
      hideNavbar={retrieveItem('embed', 'false') === 'true'}
    >
      <Outlet />
    </ManagementTemplate>
  );
};

export default RootLayout;
