import { minimatch } from 'minimatch';
import { ProcessedPolicy } from './types';

/**
 * Checks if user has the given permission. If `resources` is defined, will try to match only
 * permissions defined in the required resources (otherwise will try to match the whole policy).
 */
// eslint-disable-next-line import/prefer-default-export
export const hasPermission = <
  TAction extends string = string,
  TResource extends string = string,
>(
  policy: ProcessedPolicy<TAction, TResource>,
  permission: TAction,
  resources?: TResource[],
) => {
  // By default, all permissions are restricted
  if (!policy) return false;

  // filter statements by action
  const denyStatements: ProcessedPolicy<TAction, TResource>['statements'] = [];
  const allowStatements: ProcessedPolicy<TAction, TResource>['statements'] = [];
  policy.statements.forEach((st) => {
    if (st.actions.some((action) => minimatch(permission, action))) {
      if (st.effect === 'Allow') {
        allowStatements.push(st);
      } else if (st.effect === 'Deny') {
        denyStatements.push(st);
      }
    }
  });
  if (denyStatements.length === 0 && allowStatements.length === 0) {
    return false;
  }

  // If denying any resource in the policy and resources is not defined, deny the permission
  if (denyStatements.length !== 0 && !resources) {
    return false;
  }

  // Check resources if defined
  return !resources
    ? true
    : /**
       * All resources array should:
       * 1. be contained by at least one ALLOW clause
       * 2. not appear in any DENY clauses
       */
      resources.every(
        (resource) =>
          allowStatements.some((st) => minimatch(resource, st.resource)) &&
          !denyStatements.some((st) => minimatch(resource, st.resource)),
      );
};
