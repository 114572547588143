import React from 'react';
import ReactDOM from 'react-dom';
import { Authenticator } from '@quality24/react-auth';

import { Spinner } from '@quality24/design-system';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import routes from './routes/routes';
import runtimeConfig from './config';

Authenticator.configure({
  userPoolId:
    runtimeConfig.COGNITO_USER_POOL_ID ||
    process.env.REACT_APP_COGNITO_USER_POOL_ID ||
    '',
  userPoolClientId:
    runtimeConfig.COGNITO_CLIENT_ID ||
    process.env.REACT_APP_COGNITO_CLIENT_ID ||
    '',
  region: runtimeConfig.COGNITO_REGION || process.env.REACT_APP_COGNITO_REGION,
});

ReactDOM.render(
  <RouterProvider
    router={createBrowserRouter(routes)}
    fallbackElement={
      <div className="d-flex align-items-center justify-content-center p-4">
        <Spinner />
      </div>
    }
  />,
  document.getElementById('root') as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
