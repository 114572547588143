import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import * as React from 'react';
import classNames from 'classnames';

import { BreadCrumbItem, Breadcrumb, BreadcrumbProps } from '../../molecules';
import { Button, Heading, Icon, Image, Subtitle } from '../../atoms';

import styles from './PageHeader.module.scss';

export interface NavigateFunction {
  (delta: number): void;
  (path: string): void;
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle?: string;
  logo?: string;
  tags?: React.ReactNode;
  rightComponent?: React.ReactNode;
  showGoBack?: boolean;
  fixed?: boolean;
  rootPath?: BreadcrumbProps['rootPath'];
  breadCrumbItems?: BreadCrumbItem[];
  navigate?: NavigateFunction;
}

/**
 * <PageHeader> component
 */
const PageHeader: React.FunctionComponent<Props> = ({
  className,
  title,
  subtitle = '',
  tags,
  logo,
  rightComponent,
  rootPath,
  breadCrumbItems = [],
  showGoBack = false,
  fixed,
  navigate,
  ...htmlProps
}) => {
  return (
    <div
      className={classNames(styles.wrapper, { fixed }, className)}
      {...htmlProps}
    >
      <div className="d-flex flex-items-start gap-2">
        {showGoBack && navigate && (
          <Button variant="link" onClick={() => navigate(-1)}>
            <Icon icon={faChevronLeft} color="primary" size="lg" />
          </Button>
        )}

        <div className="d-flex flex-column gap-1">
          {breadCrumbItems.length > 0 && navigate && (
            <Breadcrumb
              rootPath={rootPath}
              items={breadCrumbItems}
              navigate={navigate}
            />
          )}

          <div className="d-flex">
            {logo && (
              <Image
                className={styles.image}
                src={logo}
                alt="Logo"
                width={40}
                height={40}
              />
            )}

            <div className="d-flex flex-column gap-1">
              <Heading as="h1" size="md" weight="bold">
                {title}
              </Heading>
              {subtitle && <Subtitle size="sm2">{subtitle}</Subtitle>}

              {tags && <div>{tags}</div>}
            </div>
          </div>
        </div>
      </div>

      {rightComponent}
    </div>
  );
};

export default PageHeader;
