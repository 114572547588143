import * as React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

export interface Props extends Omit<FontAwesomeIconProps, 'icon'> {
  className?: string;
  isSorted?: boolean;
  direction?: 'asc' | 'desc';
  disabled?: boolean;
}

const TableSortIcon: React.FunctionComponent<Props> = ({
  className,
  isSorted = false,
  direction = 'asc',
  disabled,
  ...iconProps
}: Props) => {
  // Does not show icon if disabled
  if (disabled) return null;

  // Select icon and display
  let icon = faSort;
  if (isSorted && direction === 'asc') {
    icon = faSortUp;
  } else if (isSorted && direction === 'desc') {
    icon = faSortDown;
  }
  return <FontAwesomeIcon className={className} icon={icon} {...iconProps} />;
};

export default TableSortIcon;
