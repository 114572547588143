import React from 'react';

export interface DeviceAuthContext {
  loading: boolean;
  isAuthenticated: boolean;
  signIn: (username: string, password: string) => void;
  signOut: () => void;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

// The initial authorization context
const context = React.createContext<DeviceAuthContext>({
  loading: false,
  isAuthenticated: false,
  signIn: async () => {},
  signOut: async () => {},
  setAuthenticated: () => null,
});

export default context;
