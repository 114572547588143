import * as React from 'react';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import debounce from 'lodash/debounce';
import type { DebouncedFunc } from 'lodash';
import classNames from 'classnames';
import InputWithIcon, { InputWithIconProps } from '../InputWithIcon';

import styles from './SearchBar.module.scss';

export interface Props extends Omit<InputWithIconProps, 'name'> {
  name?: string;
  padding?: 'md' | 'sm';
  debounceInterval?: number;
  showIcon?: boolean;
  /**
   * @note when using debounce mode, do NOT use "value" to try to control
   * this field. It will NOT work
   */
  debounce?: boolean;
}

const SearchBar = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name = 'searchbar',
      placeholder = 'Busca',
      debounce: applyDebounce = false,
      debounceInterval = 500,
      value,
      defaultValue,
      className,
      padding = 'md',
      onChange = () => {},
      showIcon = true,
      ...htmlElementProps
    },
    ref,
  ) => {
    /**
     * Debounce the onChange event
     */
    const debounced = React.useMemo<
      DebouncedFunc<(event: React.ChangeEvent<HTMLInputElement>) => void>
    >(
      () =>
        debounce((event) => {
          onChange(event);
        }, debounceInterval),
      [debounceInterval, onChange],
    );

    // cancel the debounce if it is running but component is closing.
    React.useEffect(() => {
      return () => debounced.cancel();
    }, [debounced]);

    const cls = classNames(
      styles.wrapper,
      { [`input-${padding}`]: padding && padding !== 'md' },
      className,
    );

    return (
      <InputWithIcon
        ref={ref}
        className={classNames(cls)}
        name={name}
        type="text"
        size={padding}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={applyDebounce ? debounced : (e) => onChange(e)}
        appendIcon={showIcon ? faMagnifyingGlass : undefined}
        {...htmlElementProps}
      />
    );
  },
);

export default SearchBar;
