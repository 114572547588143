import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { CookieStorage } from './CookieStorage';

export interface ReactAuthConfig {
  userPoolId: string;
  userPoolClientId: string;
  region?: string;
}

export class Authenticator {
  private static config: ReactAuthConfig;

  /**
   * Initializes the Amplify configuration
   * @param config
   */
  static configure(config: ReactAuthConfig) {
    this.config = config;

    Amplify.configure({
      Auth: {
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.userPoolClientId,
        region: config.region,
        storage: CookieStorage,
      },
    });
  }

  /**
   * Asserts that the class is initialized correctly
   */
  private static assertInit() {
    const clientId = this.config.userPoolClientId;
    if (!clientId) {
      throw new Error(
        'Client id not set in the Authenticator. Did you call Authenticator.configure()?',
      );
    }
  }

  /**
   * Returns the clientId value
   * @returns
   */
  static getClientId() {
    this.assertInit();
    return this.config.userPoolClientId;
  }

  /**
   * Returns current session data
   * @returns
   */
  static async currentSession() {
    this.assertInit();
    return Auth.currentSession();
  }

  /**
   * Signs user in
   * @param username
   * @param password
   * @returns
   */
  static async signIn(username: string, password: string) {
    this.assertInit();

    const res = await Auth.signIn(username, password);
    if (res.challengeName) {
      throw new Error('Unexpected challenge returned');
    }
    return res;
  }

  /**
   * Signs user out
   * @param username
   * @param password
   * @returns
   */
  static async signOut() {
    this.assertInit();
    return Auth.signOut();
  }
}
