import * as React from 'react';
import { useRouteError } from 'react-router-dom';
import DefaultErrorComponent from './DefaultErrorComponent';

export interface Props {
  children?: React.ReactNode;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage?: string;
  statusCode?: number[];
}

interface ErrorResponse {
  message?: string;
  graphQLErrors?: Array<{
    statusCode?: number;
  }>;
  networkError?: {
    statusCode?: number;
  };
}

const RouteErrorBoundary: React.FunctionComponent<Props> = ({ children }) => {
  const error = useRouteError() as ErrorResponse;

  const errorObject: ErrorBoundaryState = React.useMemo(() => {
    const statusCode = [];

    if (
      error !== undefined &&
      'graphQLErrors' in error &&
      'networkError' in error
    ) {
      const { graphQLErrors, networkError } = error;
      if (networkError && 'statusCode' in networkError) {
        statusCode.push(networkError.statusCode as number);
      }

      // Add statusCode from graphQLErrors
      graphQLErrors?.forEach((err) => {
        if (err && 'statusCode' in err) {
          statusCode.push(err.statusCode as number);
        }
      });
    }

    return { hasError: true, errorMessage: error?.message, statusCode };
  }, [error]);

  const { hasError, errorMessage, statusCode } = errorObject;

  if (hasError && !statusCode?.find((code) => code === 401)) {
    return <DefaultErrorComponent errorMessage={errorMessage} />;
  }

  return <React.Fragment key="root">{children}</React.Fragment>;
};

export default RouteErrorBoundary;
