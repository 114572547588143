import * as React from 'react';
import classNames from 'classnames';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import styles from './CustomTooltipInfo.module.scss';
import { Icon, Overlay, Text } from '..';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  info: string;
}

const CustomTooltipInfo: React.FunctionComponent<Props> = ({
  className,
  info,
  ...htmlProps
}) => {
  const [show, setShow] = React.useState(false);
  const btnRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div
      className={classNames('d-flex', className)}
      ref={btnRef}
      {...htmlProps}
    >
      <Icon
        icon={faInfoCircle}
        color="primary"
        onMouseOver={() => setShow(true)}
        onMouseOut={() => setShow(false)}
      />

      <Overlay
        show={show}
        placement="bottom-start"
        target={btnRef.current}
        onHide={() => setShow(false)}
      >
        <div className={classNames(styles.tooltip)} role="tooltip">
          <div className="d-flex flex-column gap-2">
            <Text as="span" size="sm2">
              {info}
            </Text>
          </div>
        </div>
      </Overlay>
    </div>
  );
};

export default CustomTooltipInfo;
