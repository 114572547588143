import { gql, useQuery } from '@apollo/client';
import { Connection } from '@quality24/typings';
import React from 'react';

import { useAccountIdentity } from './useAccountIdentity';
import { AllowedResource, UseAllowedResourcePayload } from './types';

export const GET_ALLOWED_DEPARTMENTS = gql`
  query GetAllowedDepartments(
    $includedDepartmentIds: [UUID!]
    $excludedDepartmentIds: [UUID!]
  ) {
    departments: hospitalDepartments(
      orderBy: ID_ASC
      filter: {
        or: [
          { id: { in: $includedDepartmentIds, notIn: $excludedDepartmentIds } }
        ]
      }
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

export interface AllowedDepartmentsQueryResult {
  departments: Connection<UseAllowedResourcePayload>;
}

/**
 * Hook Allowed Departaments
 * get list of items and ids released by `permission`
 *
 * @deprecated Use `useAllowedResources` instead
 *
 * @param userId
 * @param permission
 * @returns
 */
const useAllowedDepartments = (
  userId: string,
  permission: string,
): AllowedResource<UseAllowedResourcePayload> => {
  const { getResourceAccess, loading: accLoading } = useAccountIdentity(userId);

  const departmentPermission = React.useMemo(
    () => getResourceAccess('department', permission),
    [getResourceAccess, permission],
  );

  const { data, loading, error } = useQuery<AllowedDepartmentsQueryResult>(
    GET_ALLOWED_DEPARTMENTS,
    {
      skip: accLoading || departmentPermission.type === 'none',
      variables: {
        includedDepartmentIds:
          departmentPermission.type === 'allowlist'
            ? departmentPermission.list
            : null,
        excludedDepartmentIds:
          departmentPermission.type === 'denylist'
            ? departmentPermission.list
            : null,
      },
    },
  );

  const allowedDepartments = React.useMemo(() => {
    if (data?.departments && !loading && !error) {
      return {
        items: data.departments.nodes.map((department) => department),
        ids: data.departments.nodes.map((department) => department.id),
        isEvery: departmentPermission.type === 'all',
      };
    }
    return { items: [], ids: [], isEvery: false };
  }, [data?.departments, error, loading, departmentPermission]);

  return React.useMemo(
    () => ({ ...allowedDepartments, loading: loading || accLoading, error }),
    [accLoading, allowedDepartments, error, loading],
  );
};

export default useAllowedDepartments;
