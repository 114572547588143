import React from 'react';
import authContext, { DashboardAuthContext } from './context';

export type UseDashboardAuthHook = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
>() => DashboardAuthContext<T>;

export const useDashboardAuth: UseDashboardAuthHook = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any> = Record<string, any>,
>() => {
  const context = React.useContext<DashboardAuthContext<T>>(
    authContext as unknown as React.Context<DashboardAuthContext<T>>,
  );
  return context;
};
