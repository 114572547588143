import { TableColumn, TableDatum } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const getHiddenColumnIds = <T extends TableDatum>(
  columns: TableColumn<T>[],
) => {
  const hiddenColumns: string[] = [];

  function recursivelyPushHiddenColumns(currentColumns: TableColumn<T>[]) {
    currentColumns.forEach((column) => {
      if (column.isVisible === false) {
        hiddenColumns.push(String(column.id));
      }
      if (column.columns && column.columns.length > 0) {
        recursivelyPushHiddenColumns(column.columns);
      }
    });
  }

  recursivelyPushHiddenColumns(columns);
  return hiddenColumns;
};
